import React from 'react';

function Blog() {
  return (
    <div className="header">
      <h2>Blog</h2>
    </div>
  );
}

export default Blog;
