import React from 'react';

function Services() {
  return (
    <div className="header">
      <h2>Our Services</h2>
    </div>
  );
}

export default Services;
