import React from 'react';
import { Col, Container, Row, Accordion } from 'react-bootstrap';

const FrequentlyAskedQuestion = () => {

  return (
    <div className='happy-customers bg-light'>
      <Container>
        <Row>
          <Col lg={12} md={12}>
            <div className="section-head text-center mb-5 pb-3">
              <h1><span>Frequently </span> Asked <span>Question</span></h1>
              <p>Got A Question? We're Here To Answer! If You Don't See Your Qustion Here, Drop Us A Line On Our Contact Page.</p>
            </div>
          </Col>
          <Col lg={12} md={12}>
            <Accordion>
              <Row>
                <Col lg={6} md={12}>
                  <Row>
                    <Col md={12}>
                      <Accordion.Item eventKey="0" className='mt-4'>
                        <Accordion.Header>What makes Next2Technologies peerless?</Accordion.Header>
                        <Accordion.Body>
                          Next2Technologies firmly believes in attending to the general requirements of the clients in a thorough manner, which enables us to deliver the best iterations of the web or mobile app that are consistent with the client's visions. Our Outstanding Team Of Trained And Skilled Developers Handles 360-Degree Needs And Offers Quick Solutions For A Hassle-Free Experience.
                        </Accordion.Body>
                      </Accordion.Item>
                    </Col>
                    <Col md={12}>
                      <Accordion.Item eventKey="1" className='mt-4'>
                        <Accordion.Header>What are the advantages that you get?</Accordion.Header>
                        <Accordion.Body>
                          You Get To Experience A development journey that is specifically tailored to you and completely meets your needs. Along with other alluring offers like free app promotion, support, and maintenance, among other features for your app, we guarantee a 100% money-back guarantee.
                        </Accordion.Body>
                      </Accordion.Item>
                    </Col>
                  </Row>
                </Col>
                <Col lg={6} md={12}>
                  <Row>
                    <Col md={12} className='mt-4'>
                      <Accordion.Item eventKey="2">
                        <Accordion.Header>What are the industries Next2Technologies has worked with?</Accordion.Header>
                        <Accordion.Body>
                          Next2Technologies takes great pride in working with businesses and brands from a variety of industries. We hold great expertise in E-Commerce, Real Estate, Finance, On-Demand, Education, Home Automation and many more.
                        </Accordion.Body>
                      </Accordion.Item>
                    </Col>
                    <Col md={12} className='mt-4'>
                      <Accordion.Item eventKey="3">
                        <Accordion.Header>What are the services that Next2Technologies offers?</Accordion.Header>
                        <Accordion.Body>
                          Next2Technologies is a one-stop development solution. We provide complete development services including Android App Development, Web App Development, iOS App Development, IOT App Development, Website development and Digital marketing.
                        </Accordion.Body>
                      </Accordion.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Accordion>
          </Col>
        </Row>
      </Container>
    </div >
  );
}

export default FrequentlyAskedQuestion;
