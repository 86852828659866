import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { BsArrowRight } from "react-icons/bs";
import androidIcon from './../../assets/images/icons/android.png';
import iosIcon from './../../assets/images/icons/ios.png';
import webAppIcon from './../../assets/images/icons/web.png';
import websiteIcon from './../../assets/images/icons/website.png';
import promotionIcon from './../../assets/images/icons/promotion.png';
import iotIcon from './../../assets/images/icons/iot.png';

function ServicesWeOffer() {
  return (
    <div className="services-offer">
      <Container>
        <Row>
          <Col lg={12} md={12}>
            <div className="section-head mb-5 pb-3">
              <div>
                <h1>Services<span> We </span> Offer</h1>
                <p>
                  We bring you the best 360-degree solutions for your business or brand. We are a One-Stop Destination for all web and mobile app development solution.
                </p>
              </div>
            </div>
          </Col>
          <Col lg={12}>
            <Row>
              <Col lg={4} md={6}>
                <div className="services-offer-block bg-g1">
                  <div className="services-offer-block-inner">
                    <img src={androidIcon} alt="next2technologies-android-development" />
                    <h4>Android App Development</h4>
                    <p>We have one of the most efficient android app developers, To build and customise app for any business or brand. We work to provide solutions within your budget.</p>
                    <a href="#">
                      <span>
                        Read More
                      </span>
                      <BsArrowRight color="#363062" size={20} />
                    </a>
                  </div>
                </div>
              </Col>
              <Col lg={4} md={6}>
                <div className="services-offer-block">
                  <div className="services-offer-block-inner">
                    <img src={iosIcon} alt="next2technologies-ios-developer" />
                    <h4>iOS App Development</h4>
                    <p>We have one of the most efficient iOS app developers, To build and customise app for any business or brand. Our team will work to provide solutions within your budget.</p>
                    <a href="#">
                      <span>
                        Read More
                      </span>
                      <BsArrowRight color="#363062" size={20} />
                    </a>
                  </div>
                </div>
              </Col>
              <Col lg={4} md={6}>
                <div className="services-offer-block bg-g1">
                  <div className="services-offer-block-inner">
                    <img src={webAppIcon} alt="next2technologies-web-app" />
                    <h4>Web App Development</h4>
                    <p>With our strong skill in versatile web app development, our team offers the right blend of quality, cost, and consistent experience. We are capable of offering solutions.</p>
                    <a href="#">
                      <span>
                        Read More
                      </span>
                      <BsArrowRight color="#363062" size={20} />
                    </a>
                  </div>
                </div>
              </Col>
              <Col lg={4} md={6}>
                <div className="services-offer-block">
                  <div className="services-offer-block-inner">
                    <img src={websiteIcon} alt="next2technologies-website-development" />
                    <h4>Website Development</h4>
                    <p>We are a top-rated custom website development company, offering the best solutions to help with your online presence and offer complete satisfaction to their customers.</p>
                    <a href="#">
                      <span>
                        Read More
                      </span>
                      <BsArrowRight color="#363062" size={20} />
                    </a>
                  </div>
                </div>
              </Col>
              <Col lg={4} md={6}>
                <div className="services-offer-block bg-g1">
                  <div className="services-offer-block-inner">
                    <img src={promotionIcon} alt="next2technologies-digital-marketing" />
                    <h4>Digital Marketing</h4>
                    <p>We’ve been providing the best digital Marketing solutions for businesses wanting to expand their online presence, increase leads and grow their revenue.</p>
                    <a href="#">
                      <span>
                        Read More
                      </span>
                      <BsArrowRight color="#363062" size={20} />
                    </a>
                  </div>
                </div>
              </Col>
              <Col lg={4} md={6}>
                <div className="services-offer-block">
                  <div className="services-offer-block-inner">
                    <img src={iotIcon} alt="next2technologies-iot-dev" />
                    <h4>IOT App Development</h4>
                    <p>Speed up your responsiveness to new experiences and adapt information from associated gadgets with changing to an IoT-empowered, future-prepared association.</p>
                    <a href="#">
                      <span>
                        Read More
                      </span>
                      <BsArrowRight color="#363062" size={20} />
                    </a>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default ServicesWeOffer;
