import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import GetInTouchModal from './../../components/GetInTouchModal';
import about_hero from './../../assets/images/backgrounds/about_us.webp';

function HomeAboutUs() {

  const [getInTouchModalShow, setGetInTouchModalShow] = React.useState(false);

  return (
    <div className="home-about-us who-r-w">
      <Container>
        <Row>
          <Col lg={6} md={12}>
            <div className="who-r-w-text-box">
              <h1>Who <span>Are</span> We?</h1>
              <p>
                Next2Technologies provides Web and Mobile App development solutions for Brands and fortunately, we delivered 100+ web and mobile applications. Next2Technologies, Best for Web and Mobile app development, has Applied Solid Expertise with Top-Class Industry knowledge that helps us to Excel and Deliver <span>100% Satisfaction</span>. As a Full-Cycle Web and Mobile App development company with more than 3 years of Experience, We have Attracted Customers from all over the World. We have Extensive Experience providing Personalized development solutions for <span>Startups, SMEs and Enterprises</span> across all Industries.
              </p>
              <div className="who-r-w-count">
                <Row>
                  <Col lg={4} md={4} sm={12}>
                    <div>
                      <h1>15+</h1>
                      <h5>Developer</h5>
                    </div>
                  </Col>
                  <Col lg={4} md={4} sm={12}>
                    <div>
                      <h1>100+</h1>
                      <h5>Apps Delivered</h5>
                    </div>
                  </Col>
                  <Col lg={4} md={4} sm={12}>
                    <div>
                      <h1>90%</h1>
                      <h5>Retention Rate</h5>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
          <Col lg={6} md={12}>
            <div className="who-r-w-image-box">
              <img src={about_hero} alt="next2technologies-who-are-we" />
            </div>
          </Col>
        </Row>
        <div className="who-r-w-estimate">
          <Row>
            <Col lg={8} md={6}>
              <div className="who-r-w-estimate-text">
                <h6>LET'S WORK TOGETHER</h6>
                <h1><span>We would love to hear</span><br />from you!</h1>
              </div>
            </Col>
            <Col lg={4} md={6}>
              <div className="who-r-w-estimate-btn">
                <button onClick={() => setGetInTouchModalShow(true)}>Estimate Project</button>
              </div>
              <GetInTouchModal
                show={getInTouchModalShow}
                onHide={() => setGetInTouchModalShow(false)}
              />
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
}

export default HomeAboutUs;
