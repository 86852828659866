import React, { useState } from 'react';
import { Col, Container, Image, Row, Modal } from 'react-bootstrap';
import { BsQuote, BsStarFill } from 'react-icons/bs';
import finackoIcon from './../../assets/images/testimonial/finacko-logo.png';
import clientVideo from './../../assets/images/testimonial/happy-client-video.png';
import Slider from "react-slick";

const OurHappyCustomers = () => {

  const [testimonialVideoUrl, setTestimonialVideoUrl] = useState('');
  const [testimonial, setTestimonial] = useState([
    { client_logo: finackoIcon, comment: 'Working with Next2Technologies was an experience to remember for years. They offered dedicated time and efforts for our Food app development project!', client_name: 'Atul Mishra', client_designation: 'Director - Finacko Business' },
    { client_logo: finackoIcon, comment: 'Working with Next2Technologies was an experience to remember for years. They offered dedicated time and efforts for our Food app development project!', client_name: 'Balram Singh', client_designation: 'CEO - Sevagram Services' },
    { client_logo: finackoIcon, comment: 'Working with Next2Technologies was an experience to remember for years. They offered dedicated time and efforts for our Food app development project!', client_name: 'M.R Siddiqui', client_designation: 'Director - KhataOn App' },
  ]);
  const [showVideoModal, setShowVideoModal] = useState(false);

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <div className='happy-customers bg-white'>
      <Container>
        <Row>
          <Col lg={12} md={12}>
            <div className="section-head text-center mb-5 pb-3">
              <h1><span>Our </span>  Happy <span>Customers</span></h1>
            </div>
          </Col>
          <Col lg={12} md={12}>
            <Row>
              <Col lg={4} md={12}>
                <div class="testimonial-video">
                  <div class="video-review" data-tilt="" data-tilt-max="5" data-tilt-speed="1000">
                    <img src={clientVideo} className='img-fluid' />
                    <button class="video-link"
                      onClick={() => {
                        setTestimonialVideoUrl('https://www.youtube.com/embed/tgbNymZ7vqY');
                        setShowVideoModal(!showVideoModal);
                      }}
                    >
                      <div class="review-video-details">
                        <div class="video-icon">
                          <span class="triangle-play"></span>
                        </div>
                      </div>
                    </button>
                  </div>
                </div>
              </Col>
              <Col lg={8} md={12}>
                <Slider {...settings}>
                  {
                    testimonial && testimonial.map((item, index) => {
                      return (
                        <div key={index} className='hc-wrapper'>
                          <div className='hc-thumb'>
                            <Row className='justify-content-between'>
                              <Col>
                                <BsQuote size={62} color={`var(--bs-gray)`} />
                              </Col>
                              <Col className='align-items-end'>
                                <Image
                                  src={item.client_logo}
                                  alt="next2technologies-customers-review"
                                />
                              </Col>
                            </Row>
                            <div className='hc-comment'>
                              <p>{item.comment}</p>
                            </div>
                            <div className='hc-client'>
                              <div className='hc-client-info'>
                                <h6>{item.client_name}</h6>
                                <p>{item.client_designation}</p>
                                <div className='rating-star'>
                                  <BsStarFill color={`var(--bs-orange)`} className='me-1' />
                                  <BsStarFill color={`var(--bs-orange)`} className='me-1' />
                                  <BsStarFill color={`var(--bs-orange)`} className='me-1' />
                                  <BsStarFill color={`var(--bs-orange)`} className='me-1' />
                                  <BsStarFill color={`var(--bs-orange)`} className='me-1' />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    })
                  }
                </Slider>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container >

      <Modal show={showVideoModal} onHide={() => setShowVideoModal(!showVideoModal)} size="lg" centered>
        <iframe width={'100%'} height={'480'} style={{ padding: '10', background: '#000000', borderRadius: 5 }} src={testimonialVideoUrl}></iframe>
      </Modal>

    </div >
  );
}

export default OurHappyCustomers;
