import React from 'react';
import { Link } from "react-router-dom";
import { Container } from 'react-bootstrap';
import { AiOutlineWhatsApp } from "react-icons/ai";
import "./Navigation.css"
import logoLight from './../assets/images/logo/n2t-light.png';
import logoDark from './../assets/images/logo/n2t-dark.png';

function Navigation() {

  const [click, setClick] = React.useState(false);
  const [scroll, setScroll] = React.useState(false);

  function changeCss() {
    var navElement = document.querySelector("nav");
    this.scrollY > 100 ? navElement.style.boxShadow = "0px 0px 10px 0px #2323234D" : navElement.style.boxShadow = 'none';
    this.scrollY > 100 ? navElement.style.transition = "all 0.3s ease-out" : navElement.style.transition = "all 0.3s ease-in";
    this.scrollY > 100 ? navElement.style.backgroundColor = "#FFFFFF" : navElement.style.backgroundColor = "#FFFFFF00";
    this.scrollY > 100 ? navElement.style.backgroundColor = "#FFFFFF" : navElement.style.backgroundColor = "#FFFFFF00";
  }

  const handleClick = () => setClick(!click);

  const Close = () => setClick(false);

  React.useEffect(() => {
    window.addEventListener("scroll", changeCss, false);
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 100);
    });
  }, []);

  return (
    <>
      <div className={click ? "main-container" : ""} onClick={() => Close()}>
        <nav className="navbar" onClick={e => e.stopPropagation()}>
          <Container>
            <div className="nav-container">
              <Link to="/" className="nav-logo">
                <img src={scroll || click ? logoDark : logoLight} className="sg-logo-light" alt="Next2Technologies-Logo" />
              </Link>
              <ul className={click ? "nav-menu active" : "nav-menu"}>
                <li className="nav-item">
                  <Link className={scroll || click ? "nav-links on-scroll" : "nav-links"} to="/">Home</Link>
                </li>
                <li className="nav-item">
                  <Link className={scroll || click ? "nav-links on-scroll" : "nav-links"} to="/about-us">About us</Link>
                </li>
                <li className="nav-item">
                  <Link className={scroll || click ? "nav-links on-scroll" : "nav-links"} to="/services">Services</Link>
                </li>
                <li className="nav-item">
                  <Link className={scroll || click ? "nav-links on-scroll" : "nav-links"} to="/portfolio">Portfolio</Link>
                </li>
                <li className="nav-item">
                  <Link className={scroll || click ? "nav-links on-scroll" : "nav-links"} to="/blog">Blog</Link>
                </li>
                <li className="nav-item">
                  <Link className={scroll || click ? "nav-links on-scroll" : "nav-links"} to="/contact-us">Contact us</Link>
                </li>
                <div className="whatsapp-chat-btn">
                  <a href="https://api.whatsapp.com/send?phone=917800111018">
                    <AiOutlineWhatsApp color="#FFFFFF" size={24} />
                    <span>CHAT NOW</span>
                  </a>
                </div>
              </ul>
              <div className={click ? "nav-icon open" : "nav-icon"} onClick={handleClick}>
                <div className={scroll ? "menu-btn-burger menu-btn-on-scroll" : "menu-btn-burger"}></div>
              </div>
            </div>
          </Container>
        </nav>
      </div>
    </>
  );
}

export default Navigation;
