import React from 'react';

function Contact() {
  return (
    <div className="header">
      <h2>Contact Us</h2>
    </div>
  );
}

export default Contact;
