import React from 'react';

function About() {
  return (
    <div className="header">
      <h2>About Us</h2>
    </div>
  );
}

export default About;
