import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

function EnterprisesTakenCare() {
  return (
    <div className="home-enterprises-care enterprises-take-care">
      <Container>
        <Row>
          <Col lg={12} md={12}>
            <div className="enterprises-take-care-text-box">
              <h1>Industries <span>We Have</span> Taken care <span>of</span></h1>
              <p>
                Over the past Years, we have developed & delivered web and mobile aplications to various Industries.
              </p>
            </div>
          </Col>
          <div className="enterprises-block-wrapper">
            <Row>
              <Col lg={3} md={6} xs={6}>
                <div className="enterprises-block enterprises-block-y">
                  <div className="enterprises-block-inner">
                    <img src={require('./../../assets/images/icons/shopping.png')} alt="next2technologies-icon" />
                    <p>Shopping</p>
                  </div>
                </div>
              </Col>
              <Col lg={3} md={6} xs={6}>
                <div className="enterprises-block enterprises-block-x">
                  <div className="enterprises-block-inner">
                    <img src={require('./../../assets/images/icons/home-services.png')} alt="next2technologies-icon" />
                    <p>Home Services</p>
                  </div>
                </div>
              </Col>
              <Col lg={3} md={4} xs={6}>
                <div className="enterprises-block enterprises-block-y">
                  <div className="enterprises-block-inner">
                    <img src={require('./../../assets/images/icons/real-estate.png')} alt="next2technologies-icon" />
                    <p>Real Estate</p>
                  </div>
                </div>
              </Col>
              <Col lg={3} md={4} xs={6}>
                <div className="enterprises-block enterprises-block-x">
                  <div className="enterprises-block-inner">
                    <img src={require('./../../assets/images/icons/education.png')} alt="next2technologies-icon" />
                    <p>Education</p>
                  </div>
                </div>
              </Col>
              <Col lg={3} md={4} xs={6}>
                <div className="enterprises-block enterprises-block-y">
                  <div className="enterprises-block-inner">
                    <img src={require('./../../assets/images/icons/iot.png')} alt="next2technologies-icon" />
                    <p>IOT</p>
                  </div>
                </div>
              </Col>
              <Col lg={3} md={4} xs={6}>
                <div className="enterprises-block enterprises-block-x">
                  <div className="enterprises-block-inner">
                    <img src={require('./../../assets/images/icons/demand.png')} alt="next2technologies-icon" />
                    <p>On-Demand</p>
                  </div>
                </div>
              </Col>
              <Col lg={3} md={4} xs={6}>
                <div className="enterprises-block enterprises-block-y">
                  <div className="enterprises-block-inner">
                    <img src={require('./../../assets/images/icons/finance-accounting.png')} alt="next2technologies-icon" />
                    <p>Finance & Accounting</p>
                  </div>
                </div>
              </Col>
              <Col lg={3} md={4} xs={6}>
                <div className="enterprises-block enterprises-block-x">
                  <div className="enterprises-block-inner">
                    <img src={require('./../../assets/images/icons/delivery-system.png')} alt="next2technologies-icon" />
                    <p>Delivery System</p>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Row>
      </Container>
    </div>
  );
}

export default EnterprisesTakenCare;
