import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import consultation from './../../assets/images/icons/consultation.png';
import promotion from './../../assets/images/icons/promotion.png';
import support from './../../assets/images/icons/free-support.png';
import maintenance from './../../assets/images/icons/maintenance.png';

function ExtraordinaryOffer() {
  return (
    <div className="extra-offer">
      <Container>
        <Row>
          <Col lg={6} md={12}>
            <div className="section-head mb-5 pb-3">
              <div>
                <h1>Extraordinary<span><br/>Advantages </span>We Offer</h1>
                <p>
                  Next2Technologies presents to you a bunch of exclusive advantages that will make you want more and more affiliations. Our advantages make us stand out helping us be the number one client servicing development company.
                </p>
              </div>
            </div>
          </Col>
          <Col lg={6} md={12}>
            <Row>
              <Col lg={6} md={6}>
                <div className="extra-offer-block">
                  <div className="extra-circle-effect-lg"/>
                  <div className="extra-offer-block-inner">
                    <img src={consultation} alt="next2technologies-consultation"/>
                    <h3>100% Free</h3>
                    <p>Consultation</p>
                  </div>
                </div>
              </Col>
              <Col lg={6} md={6}>
                <div className="extra-offer-block">
                  <div className="extra-circle-effect-lg"/>
                  <div className="extra-offer-block-inner">
                    <img src={promotion} alt="next2technologies-promotion"/>
                    <h3>6 Months</h3>
                    <p>Free Promotion</p>
                  </div>
                </div>
              </Col>
              <Col lg={6} md={6}>
                <div className="extra-offer-block">
                  <div className="extra-circle-effect-lg"/>
                  <div className="extra-offer-block-inner">
                    <img src={support} alt="next2technologies-support"/>
                    <h3>Free 2 Year</h3>
                    <p>Helpdesk Support</p>
                  </div>
                </div>
              </Col>
              <Col lg={6} md={6}>
                <div className="extra-offer-block">
                  <div className="extra-circle-effect-lg"/>
                  <div className="extra-offer-block-inner">
                    <img src={maintenance} alt="next2technologies-maintenance"/>
                    <h3>Free 1 Year</h3>
                    <p>Maintenance</p>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default ExtraordinaryOffer;
