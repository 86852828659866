import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { IoMailOutline, IoCallOutline, IoLocationOutline } from "react-icons/io5";
import { TbCircleDot } from "react-icons/tb";
import { FaBehance, FaDribbble, FaFacebook, FaInstagram, FaLinkedinIn, FaPinterest, FaTwitter, FaYoutube } from "react-icons/fa";
import './Footer.css';

function Footer() {
  return (
    <div className='footer bg-white'>
      <Container>
        <Row>
          <Col lg={3} md={6} sm={12}>

          </Col>
          <Col lg={3} md={6} sm={12}>
            <h5>Company</h5>
            <div class="foot-link">
              <ul>
                <li class="foot-hover">
                  <a href="/" className='ms-2'><TbCircleDot size={16} color='var(--bs-primary)' /> Home</a>
                </li>
                <li class="foot-hover">
                  <a href="/" className='ms-2'><TbCircleDot size={16} color='var(--bs-primary)' /> About Us</a>
                </li>
                <li class="foot-hover">
                  <a href="/" className='ms-2'><TbCircleDot size={16} color='var(--bs-primary)' /> Contact Us</a>
                </li>
                <li class="foot-hover">
                  <a href="/" className='ms-2'><TbCircleDot size={16} color='var(--bs-primary)' /> Terms & Conditions</a>
                </li>
                <li class="foot-hover">
                  <a href="/" className='ms-2'><TbCircleDot size={16} color='var(--bs-primary)' /> Privacy Policy</a>
                </li>
                <li class="foot-hover">
                  <a href="/" className='ms-2'><TbCircleDot size={16} color='var(--bs-primary)' /> SiteMap</a>
                </li>
              </ul>
            </div>
          </Col>
          <Col lg={3} md={6} sm={12}>
            <h5>Our Sevices</h5>
            <div class="foot-link">
              <ul>
                <li class="foot-hover">
                  <a href="/" className='ms-2'><TbCircleDot size={16} color='var(--bs-primary)' /> Android App Development</a>
                </li>
                <li class="foot-hover">
                  <a href="/" className='ms-2'><TbCircleDot size={16} color='var(--bs-primary)' /> iOS App Development</a>
                </li>
                <li class="foot-hover">
                  <a href="/" className='ms-2'><TbCircleDot size={16} color='var(--bs-primary)' /> Web App Development</a>
                </li>
                <li class="foot-hover">
                  <a href="/" className='ms-2'><TbCircleDot size={16} color='var(--bs-primary)' /> Website Development</a>
                </li>
                <li class="foot-hover">
                  <a href="/" className='ms-2'><TbCircleDot size={16} color='var(--bs-primary)' /> IOT App Development</a>
                </li>
                <li class="foot-hover">
                  <a href="/" className='ms-2'><TbCircleDot size={16} color='var(--bs-primary)' /> Digital Marketing</a>
                </li>
              </ul>
            </div>
          </Col>
          <Col lg={3} md={6} sm={12}>
            <h5>Contact Us</h5>
            <div class="foot-link">
              <ul>
                <li>
                  <IoMailOutline size={20} color='var(--bs-primary)' />
                  <div className='foot-contact-info'>
                    <h6 className='fw-bold'>Email</h6>
                    <p className='mb-2 pb-2'>info@next2technologies.com</p>
                  </div>
                </li>
                <li>
                  <IoCallOutline size={20} color='var(--bs-primary)' />
                  <div className='foot-contact-info'>
                    <h6 className='fw-bold'>Phone</h6>
                    <p className='mb-2 pb-2'>+91 78 00 111 018</p>
                  </div>
                </li>
                <li>
                  <IoLocationOutline size={20} color='var(--bs-primary)' />
                  <div className='foot-contact-info'>
                    <h6 className='fw-bold'>Address</h6>
                    <p className='mb-0'>238 C/4R, NCR Road, Harwara</p>
                    <p className='mb-2 pb-2'>Dhoomanganj, Allahabad</p>
                  </div>
                </li>
              </ul>
            </div>
          </Col>
        </Row>
        <hr />
        <div class="justify-content-center text-center py-3 row">
          <div class="col">
            <div class="foot-social-icons">
              <ul>
                <li>
                  <a href="https://www.facebook.com/next2technologies" target='_blank'>
                    <FaFacebook />
                  </a>
                </li>
                <li>
                  <a href="https://www.instagram.com/next2technologies" target='_blank'>
                    <FaInstagram />
                  </a>
                </li>
                <li>
                  <a href="https://twitter.com/next2technologies" target='_blank'>
                    <FaTwitter />
                  </a>
                </li>
                <li>
                  <a href="https://www.linkedin.com/company/next2technologies" target='_blank'>
                    <FaLinkedinIn />
                  </a>
                </li>
                <li>
                  <a href="https://www.youtube.com/@next2technologies" target='_blank'>
                    <FaYoutube />
                  </a>
                </li>
                <li>
                  <a href="https://www.pinterest.com/next2technologies" target='_blank'>
                    <FaPinterest />
                  </a>
                </li>
                <li>
                  <a href="https://dribbble.com/next2technologies" target='_blank'>
                    <FaDribbble />
                  </a>
                </li>
                <li>
                  <a href="https://www.behance.net/next2technologies" target='_blank'>
                    <FaBehance />
                  </a>
                </li>
              </ul>
            </div>
            <div class="copyright">
              <p>Copyright © 2019-2024 Next2Technologies. All Rights Reserved.</p>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default Footer;
