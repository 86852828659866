import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import GetInTouchModal from './../../components/GetInTouchModal';

function DevelopmentProcess() {

  const [getInTouchModalShow, setGetInTouchModalShow] = React.useState(false);

  return (
    <div className="develop-process bg-white">
      <Container>
        <Row>
          <div className="section-head">
            <div className="text-center">
              <h1>Development<span><br />Process </span>Step-By-Step</h1>
              <p>
                Here we share step-by-step web and mobile application development process with you.
              </p>
            </div>
          </div>
        </Row>
        <Row>
          <Col lg={4} md={12}>
            <div className="develop-block-1">
              <h3>Strategy</h3>
              <p>Define strategic goals for evolving your ideas into a successful application.</p>
            </div>
            <div className="develop-block-2">
              <h3>Analysis & Planning</h3>
              <p>Identify requirements, define team stucture, and prepare product roadmap.</p>
            </div>
            <div className="develop-block-3">
              <h3>UI/UX Design</h3>
              <p>Create seamless and effortless user experiences with a polished design.</p>
            </div>
          </Col>
          <Col lg={4} md={12}>
            <div className="d-flex h-100 w-100 align-items-center justify-content-center">
              <div className="d-flex bg-100-IMG">

              </div>
            </div>
          </Col>
          <Col lg={4} md={12}>
            <div>
              <div className="develop-block-4">
                <h3>Development</h3>
                <p>Develop backend, API, and web or mobile app following agile development process.</p>
              </div>
              <div className="develop-block-5">
                <h3>Testing</h3>
                <p>Validate your app's quality thoroughly with a series of testing criteria.</p>
              </div>
              <div className="develop-block-6">
                <h3>Deployment & Support</h3>
                <p>Deploy Application on the your domain, Apple and Google App stores with support.</p>
              </div>
            </div>
          </Col>
        </Row>
        <div className="who-r-w-estimate">
          <Row>
            <Col lg={8} md={6}>
              <div className="who-r-w-estimate-text">
                <h6>LET'S WORK TOGETHER</h6>
                <h1><span>We would love to hear</span><br />from you!</h1>
              </div>
            </Col>
            <Col lg={4} md={6}>
              <div className="who-r-w-estimate-btn">
                <button onClick={() => setGetInTouchModalShow(true)}>Estimate Project</button>
              </div>
              <GetInTouchModal
                show={getInTouchModalShow}
                onHide={() => setGetInTouchModalShow(false)}
              />
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  )
}

export default DevelopmentProcess;
