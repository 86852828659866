import React from 'react';
import { BiChevronRight } from "react-icons/bi";
import { TypeAnimation } from 'react-type-animation';
import GetInTouchModal from './../../components/GetInTouchModal';
import './index.css';
import HomeAboutUs from './home-about-us';
import EnterprisesTakenCare from './enterprises-taken-care';
import OurLatestWork from './our-latest-work';
import ExtraordinaryOffer from './extraordinary-offer';
import ServicesWeOffer from './services-we-offer';
import DevelopmentProcess from './development-process';
import ExploreTechnologicalStack from './explore-technological-stack';
import OurHappyCustomers from './our-happy-customers';
import FrequentlyAskedQuestion from './frequently-asked-question';

function Home() {

  const [getInTouchModalShow, setGetInTouchModalShow] = React.useState(false);

  return (
    <div>
      <div className="main-home">
        <div className="hero-section">
          <div>
            <h1>
              <span>#1</span>
              <br />
              <b>Web </b>
              and
              <b> Mobile </b>
              App Development Company in <b>India</b>
            </h1>
            <TypeAnimation
              sequence={['Web App Development', 1000, 'Android App Development', 1000, 'iOS App Development', 1000, 'SEO & Digital Marketing', 1000]}
              speed={75} // Must be in range between 1 and 99!
              wrapper="h2"
              repeat={Infinity}
            />
            <h5>
              Take a taste of success with our web and app services
            </h5>
          </div>
          <div className="hero-btn">
            <button onClick={() => setGetInTouchModalShow(true)}>
              GET IN TOUCH
              <BiChevronRight color="#FFFFFF" size={20} />
            </button>
          </div>
          <GetInTouchModal
            show={getInTouchModalShow}
            onHide={() => setGetInTouchModalShow(false)}
          />
        </div>
      </div>

      <HomeAboutUs />

      <EnterprisesTakenCare />

      <OurLatestWork />

      <ExtraordinaryOffer />

      <ServicesWeOffer />

      <DevelopmentProcess />

      <ExploreTechnologicalStack />

      <OurHappyCustomers />

      <FrequentlyAskedQuestion />

    </div>
  );
}

export default Home;
