import React from 'react';

function Portfolio() {
  return (
    <div className="header">
      <h2>Portfolio</h2>
    </div>
  );
}

export default Portfolio;
