import React, { useState } from 'react';
import { Container, Row, Col, Tab, Tabs } from 'react-bootstrap';
import ReactJsIcon from '../../assets/images/other-logo/react-js.png';
import NextJsIcon from '../../assets/images/other-logo/next-js.png';
import AngularJsIcon from '../../assets/images/other-logo/angular-js.png';
import Html5Icon from '../../assets/images/other-logo/html-5.png';
import BootstrapIcon from '../../assets/images/other-logo/bootstrap.png';
import FlutterIcon from '../../assets/images/other-logo/flutter.png';
import ReactNativeIcon from '../../assets/images/other-logo/react-native.png';
import ObjectiveCIcon from '../../assets/images/other-logo/objective-c.png';
import SwiftStackIcon from '../../assets/images/other-logo/swift-stack.png';
import AppleWatchIcon from '../../assets/images/other-logo/apple-watch.png';
import JavaIcon from '../../assets/images/other-logo/java.png';
import IonicIcon from '../../assets/images/other-logo/ionic.png';
import WearOs from '../../assets/images/other-logo/wear-os.png';
import ReactNative from '../../assets/images/other-logo/react-native.png';
import OnsenUi from '../../assets/images/other-logo/onsen-ui.png';

function ExploreTechnologicalStack() {

  const [key, setKey] = useState('web');

  return (
    <div className='technological-stack bg-light'>
      <Container>
        <Row>
          <Col lg={12} md={12}>
            <div className="section-head text-center mb-5 pb-3">
              <div>
                <h1>Explore <span> Our </span><br />Technological Stack</h1>
                <p>
                  We never hold back when it comes to implementing different technologies in our app development strategy.<br />Our speciality is implementing the most recent trends and technologies.
                </p>
              </div>
            </div>
          </Col>
          <Col lg={12} md={12}>
            <Tabs
              activeKey={key}
              onSelect={(k) => setKey(k)}
              className="mb-5 pb-3 custom-tab-button"
            >
              <Tab eventKey="web" title="Web App">
                <div className='tech-stack-body'>
                  <div className='row justify-content-center'>
                    <div className='col-lg-2 col-md-6 col-6'>
                      <div className='tech-stack-box'>
                        <img src={ReactJsIcon} alt="next2technologies-technology-stack" className="img-fluid" />
                        <h6>React Js</h6>
                      </div>
                    </div>
                    <div className='col-lg-2 col-md-6 col-6'>
                      <div className='tech-stack-box'>
                        <img src={NextJsIcon} alt="next2technologies-technology-stack" className="img-fluid" />
                        <h6>Next Js</h6>
                      </div>
                    </div>
                    <div className='col-lg-2 col-md-6 col-6'>
                      <div className='tech-stack-box'>
                        <img src={AngularJsIcon} alt="next2technologies-technology-stack" className="img-fluid" />
                        <h6>Angular Js</h6>
                      </div>
                    </div>
                    <div className='col-lg-2 col-md-6 col-6'>
                      <div className='tech-stack-box'>
                        <img src={Html5Icon} alt="next2technologies-technology-stack" className="img-fluid" />
                        <h6>HTML 5</h6>
                      </div>
                    </div>
                    <div className='col-lg-2 col-md-6 col-6'>
                      <div className='tech-stack-box'>
                        <img src={BootstrapIcon} alt="next2technologies-technology-stack" className="img-fluid" />
                        <h6>Bootstrap</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </Tab>
              <Tab eventKey="android" title="Android App">
                <div className='tech-stack-body'>
                  <div className='row justify-content-center'>
                    <div className='col-lg-2 col-md-6 col-6'>
                      <div className='tech-stack-box'>
                        <img src={ReactNative} alt="next2technologies-technology-stack" className="img-fluid" />
                        <h6>React Native</h6>
                      </div>
                    </div>
                    <div className='col-lg-2 col-md-6 col-6'>
                      <div className='tech-stack-box'>
                        <img src={JavaIcon} alt="next2technologies-technology-stack" className="img-fluid" />
                        <h6>Java</h6>
                      </div>
                    </div>
                    <div className='col-lg-2 col-md-6 col-6'>
                      <div className='tech-stack-box'>
                        <img src={IonicIcon} alt="next2technologies-technology-stack" className="img-fluid" />
                        <h6>Ionic</h6>
                      </div>
                    </div>
                    <div className='col-lg-2 col-md-6 col-6'>
                      <div className='tech-stack-box'>
                        <img src={WearOs} alt="next2technologies-technology-stack" className="img-fluid" />
                        <h6>Wear OS</h6>
                      </div>
                    </div>
                    <div className='col-lg-2 col-md-6 col-6'>
                      <div className='tech-stack-box'>
                        <img src={OnsenUi} alt="next2technologies-technology-stack" className="img-fluid" />
                        <h6>Onsen UI</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </Tab>
              <Tab eventKey="ios" title="IOS App">
                <div className='tech-stack-body'>
                  <div className='row justify-content-center'>
                    <div className='col-lg-2 col-md-6 col-6'>
                      <div className='tech-stack-box'>
                        <img src={FlutterIcon} alt="next2technologies-technology-stack" className="img-fluid" />
                        <h6>Flutter</h6>
                      </div>
                    </div>
                    <div className='col-lg-2 col-md-6 col-6'>
                      <div className='tech-stack-box'>
                        <img src={ReactNativeIcon} alt="next2technologies-technology-stack" className="img-fluid" />
                        <h6>React Native</h6>
                      </div>
                    </div>
                    <div className='col-lg-2 col-md-6 col-6'>
                      <div className='tech-stack-box'>
                        <img src={ObjectiveCIcon} alt="next2technologies-technology-stack" className="img-fluid" />
                        <h6>Objective-C</h6>
                      </div>
                    </div>
                    <div className='col-lg-2 col-md-6 col-6'>
                      <div className='tech-stack-box'>
                        <img src={AppleWatchIcon} alt="next2technologies-technology-stack" className="img-fluid" />
                        <h6>Apple Watch</h6>
                      </div>
                    </div>
                    <div className='col-lg-2 col-md-6 col-6'>
                      <div className='tech-stack-box'>
                        <img src={SwiftStackIcon} alt="next2technologies-technology-stack" className="img-fluid" />
                        <h6>Swift</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </Tab>
            </Tabs>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default ExploreTechnologicalStack;
