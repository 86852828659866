import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { Container, Row, Col } from 'react-bootstrap';
import axios from 'axios';
import { MdClose } from "react-icons/md";
import './GetInTouchModal.css';
import getInTouchHeroImage from './../assets/images/backgrounds/get-in-touch.svg';

function GetInTouchModal(props) {

  const form = React.useRef();
  const [countries, setCountries] = React.useState([]);
  const [contactDetails, setContactDetails] = React.useState({
    name: '',
    email: '',
    country: '',
    phone_number: '',
    service: '',
    budget: '',
    message: '',
    date: new Date(),
  });

  const getAllCountryNames = () => {
    axios({
      method: 'get',
      url: 'https://restcountries.com/v2/all'
    })
    .then(function (response) {
      //console.log('Country : ',response.data);
      setCountries(response.data);
    });
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setContactDetails({
      ...contactDetails,
      [name] : value
    });
  }

  React.useEffect(()=> {
    getAllCountryNames();
  },[]);

  const handleOnSubmit = (e) => {
    e.preventDefault();

  }

  return (
    <Modal
      {...props}
      size="lg"
      backdrop="static"
      centered
    >
      <Modal.Body>
        <div className="close-modal">
          <button onClick={props.onHide}>
            <MdClose color="white" size={30}/>
          </button>
        </div>
        <Container>
          <Row>
            <Col lg={6} md={12} sm={12}>
              <div className="get-in-touch">
                <div className="get-in-touch-info">
                  <h2>Want To Discuss</h2>
                  <h1>Your Idea?</h1>
                  <hr/>
                  <h6>We Are Here 24x7 For You.</h6>
                </div>
                <div className="get-in-touch-hero">
                  <img src={getInTouchHeroImage} alt="Next2Technologies-Get-in-touch" />
                </div>
              </div>
            </Col>
            <Col lg={6} md={12} sm={12}>
              <div className="get-in-touch-form">
                <form ref={form} onSubmit={handleOnSubmit}>
                  <div className="text-input">
                    <input
                      placeholder="Name *"
                      type="text"
                      name="name"
                      onChange={(event)=> handleChange(event)}
                      required
                    />
                  </div>
                  <div className="text-input">
                    <input
                      placeholder="Email *"
                      type="email"
                      name="email"
                      onChange={(event)=> handleChange(event)}
                      required
                    />
                  </div>
                  <div className="select-input">
                    <select name="country" onChange={(event)=> handleChange(event)}>
                      <option value="">Select Country</option>
                      {
                        countries && countries.map((item, index)=> {
                          return (
                            <option key={index} value={item.name}>{item.name}</option>
                          )
                        })
                      }
                    </select>
                  </div>
                  <div className="text-input">
                    <input
                      placeholder="Contact Number"
                      type="contact"
                      name="phone_number"
                      onChange={(event)=> handleChange(event)}
                    />
                  </div>
                  <div className="select-input">
                    <select name="service" onChange={(event)=> handleChange(event)}>
                      <option value="">Choose Your Service</option>
                      <option value="Android App Development">Android App Development</option>
                      <option value="IOS App Development">IOS App Development</option>
                      <option value="Web App Development">Web App Development</option>
                      <option value="Website Development">Website Development</option>
                      <option value="SEO & Digital Marketing">SEO & Digital Marketing</option>
                    </select>
                  </div>
                  <div className="select-input">
                    <select name="budget" onChange={(event)=> handleChange(event)}>
                      <option value="">Your Budget</option>
                      <option value="Less than $3K">Less than $3K</option>
                      <option value="Between $3K - $10K">Between $3K - $10K</option>
                      <option value="Between $10K - $25K">Between $10K - $25K</option>
                      <option value="Between $25K - $50K">Between $25K - $50K</option>
                      <option value="More than $50K">More than $50K</option>
                    </select>
                  </div>
                  <div className="text-input">
                    <textarea
                      placeholder="Message"
                      row={4}
                      name="message"
                      onChange={(event)=> handleChange(event)}
                    />
                  </div>
                  <div className="getInTouch-btn">
                    <button type="submit">
                      Send Message
                    </button>
                  </div>
                </form>
              </div>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
}

export default GetInTouchModal;
