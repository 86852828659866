import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

function OurLatestWork() {

  return (
    <div className="our-latest-work-section">
      <Container>
        <Row>
          <Col lg={12} md={12}>
            <div className="section-head text-center">
              <h1><span>Our</span> Latest <span>Work</span></h1>
              <p>
                Over the past Years, we have developed & delivered web and mobile aplications to various Industries.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default OurLatestWork;
